import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Navbar from "./views/navbar";
import Contacto from "./views/Contacto";
import Homepage from "./views/Homepage";
import DiseñoSonoro from "./views/DiseñoSonoro";
import Mix from "./views/Mix";
import ArregloMusical from "./views/ArregloMusical";
import AboutMe from "./views/AboutMe";

import Prox from "./views/Prox.js";
const App = () => {
  return (
    <div>
      <header>
        <Navbar />
      </header>

      <Router>
        <Routes>
          <Route path="/DisenoSonoro" element={<DiseñoSonoro />} />

          <Route path="/Mix-Master" element={<Mix />} />

          <Route path="/Composicion" element={<ArregloMusical />} />

          <Route path="/Contacto" element={<Contacto />} />

          <Route path="/SobreMi" element={<AboutMe />} />

          <Route path="/Proximamente" element={<Prox />} />

          <Route path="/*" element={<Homepage />} />

          <Route path="/" element={<Homepage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
