import React, { useMemo, useCallback, useRef, useState } from "react";
import { WaveSurfer, WaveForm } from "wavesurfer-react";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.min";
import "../css/mix.css";
import { FiPause } from "react-icons/fi";
import { FiPlay } from "react-icons/fi";
import { FiVolume2, FiVolume1, FiVolume } from "react-icons/fi";

import entradaMiranda from "../multimedia/Mix/Entrada Miranda Mix y Mst.wav";
import entradaMatisse from "../multimedia/Mix/Entrada Matisse Mix y Mst.wav";
import salidaMatisse from "../multimedia/Mix/Salida Matisse Mix y Mst.wav";
import apuesta from "../multimedia/Mix/APUESTAS MIX FdO.wav";
import sabrasMas from "../multimedia/Mix/Sabrás mix by Ricardo Flores.wav";
import cohete from "../multimedia/Mix/Cohete-Renee MASTER ST.wav";

const Mix = () => {
  const plugins = useMemo(() => {
    return [{ plugin: CursorPlugin }].filter(Boolean);
  }, []);

  //Wave 1 / Entrada Miranda
  const [isPlaying1, setIsPlaying1] = useState(false);
  const [volume1, setVolume1] = useState(1);

  const waveSurferRef = useRef();
  const handleWSMount = useCallback((waveSurfer) => {
    waveSurferRef.current = waveSurfer;
    if (waveSurferRef.current) {
      if (waveSurferRef.current) {
        waveSurferRef.current.load(entradaMiranda);
        waveSurferRef.current.setVolume(1);
      }
    }
  });
  const play = () => {
    setIsPlaying1(!isPlaying1);
    waveSurferRef.current.playPause();
  };
  //Wave 2 / Entrada Matisse
  const [isPlaying2, setIsPlaying2] = useState(false);
  const [volume2, setVolume2] = useState(1);

  const waveSurferRef2 = useRef();
  const handleWSMount2 = useCallback((waveSurfer) => {
    waveSurferRef2.current = waveSurfer;
    if (waveSurferRef2.current) {
      if (waveSurferRef2.current) {
        waveSurferRef2.current.load(entradaMatisse);
        waveSurferRef2.current.setVolume(1);
      }
    }
  });
  const play2 = () => {
    setIsPlaying2(!isPlaying2);
    waveSurferRef2.current.playPause();
  };
  //Wave 3 / Salida Matisse
  const [isPlaying3, setIsPlaying3] = useState(false);
  const [volume3, setVolume3] = useState(1);

  const waveSurferRef3 = useRef();
  const handleWSMount3 = useCallback((waveSurfer) => {
    waveSurferRef3.current = waveSurfer;
    if (waveSurferRef3.current) {
      if (waveSurferRef3.current) {
        waveSurferRef3.current.load(salidaMatisse);
        waveSurferRef3.current.setVolume(1);
      }
    }
  });
  const play3 = () => {
    setIsPlaying3(!isPlaying3);
    waveSurferRef3.current.playPause();
  };

  //Wave 4 / Apuesta
  const [isPlaying4, setIsPlaying4] = useState(false);
  const [volume4, setVolume4] = useState(1);

  const waveSurferRef4 = useRef();
  const handleWSMount4 = useCallback((waveSurfer) => {
    waveSurferRef4.current = waveSurfer;
    if (waveSurferRef4.current) {
      if (waveSurferRef4.current) {
        waveSurferRef4.current.load(apuesta);
        waveSurferRef4.current.setVolume(1);
      }
    }
  });
  const play4 = () => {
    setIsPlaying4(!isPlaying4);
    waveSurferRef4.current.playPause();
  };

  //Wave 5 / Sabras Mas
  const [isPlaying5, setIsPlaying5] = useState(false);
  const [volume5, setVolume5] = useState(1);

  const waveSurferRef5 = useRef();
  const handleWSMount5 = useCallback((waveSurfer) => {
    waveSurferRef5.current = waveSurfer;
    if (waveSurferRef5.current) {
      if (waveSurferRef5.current) {
        waveSurferRef5.current.load(sabrasMas);
        waveSurferRef5.current.setVolume(1);
      }
    }
  });
  const play5 = () => {
    setIsPlaying5(!isPlaying5);
    waveSurferRef5.current.playPause();
  };

  //Wave 6 / Cohete
  const [isPlaying6, setIsPlaying6] = useState(false);
  const [volume6, setVolume6] = useState(1);

  const waveSurferRef6 = useRef();
  const handleWSMount6 = useCallback((waveSurfer) => {
    waveSurferRef6.current = waveSurfer;
    if (waveSurferRef6.current) {
      if (waveSurferRef6.current) {
        waveSurferRef6.current.load(cohete);
        waveSurferRef6.current.setVolume(1);
      }
    }
  });
  const play6 = () => {
    setIsPlaying6(!isPlaying6);
    waveSurferRef6.current.playPause();
  };

  return (
    <div>
      <div>
        <section className="bannerHomeM" id="banner">
          <div className="textoBanner">
            <h1 className="nombreHome">Mix/Master</h1>
          </div>
        </section>
        <section className="audiosMix">
          <div className="filtro"></div>

          {/* Matisse */}
          <div className="contenidoMix">
            {/* Entrada Miranda */}
            <h3 className="tipoMix">Entrada “Miranda Santizo”</h3>
            <div className="wave">
              <WaveSurfer plugins={plugins} onMount={handleWSMount}>
                <WaveForm id="waveform1"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="mixMasterIcons">
                  <a onClick={play} className="musicPlaying">
                    {isPlaying1 == false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="mixMasterIcons2">
                  <span className="volumeIcon">
                    {volume1 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume1 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInput"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume1}
                    onChange={(e) => {
                      setVolume1(e.target.valueAsNumber);
                      waveSurferRef.current.setVolume(volume1);
                    }}
                  />
                </div>
              </div>
            </div>
            <br />
            {/* Entrada Matisse */}
            <h3 className="tipoMix">Entrada “Matisse”</h3>
            <div className="wave">
              <WaveSurfer plugins={plugins} onMount={handleWSMount2}>
                <WaveForm id="waveform2"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="mixMasterIcons">
                  <a onClick={play2} className="musicPlaying">
                    {isPlaying2 == false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="mixMasterIcons2">
                  <span className="volumeIcon">
                    {volume2 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume2 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInput"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume2}
                    onChange={(e) => {
                      setVolume2(e.target.valueAsNumber);
                      waveSurferRef2.current.setVolume(volume2);
                    }}
                  />
                </div>
              </div>
            </div>
            <br />
            {/* Salida Matisse */}
            <h3 className="tipoMix">Salida “Matisse”</h3>
            <div className="wave">
              <WaveSurfer plugins={plugins} onMount={handleWSMount3}>
                <WaveForm id="waveform3"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="mixMasterIcons">
                  <a onClick={play3} className="musicPlaying">
                    {isPlaying3 == false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="mixMasterIcons2">
                  <span className="volumeIcon">
                    {volume3 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume3 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInput"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume3}
                    onChange={(e) => {
                      setVolume3(e.target.valueAsNumber);
                      waveSurferRef3.current.setVolume(volume3);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="abajoMix">
              <div className="textoMix">
                <span className="descMix">
                  Composición, Mezcla y Masterización de canciones originales
                  para concierto realizado en el Tecnológico de Monterrey Campus
                  Estado de México, el concierto contó con la participación de
                  la banda Matisse y la artista Miranda Santizo como
                  presentación inicial.
                </span>
              </div>
            </div>
          </div>

          {/* Apuesta */}
          <div className="contenidoMix">
            <h3 className="tipoMix">Apuestas - Flores de Octubre</h3>
            <div className="wave">
              <WaveSurfer plugins={plugins} onMount={handleWSMount4}>
                <WaveForm id="waveform4"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="mixMasterIcons">
                  <a onClick={play4} className="musicPlaying">
                    {isPlaying4 == false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="mixMasterIcons2">
                  <span className="volumeIcon">
                    {volume4 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume4 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInput"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume4}
                    onChange={(e) => {
                      setVolume4(e.target.valueAsNumber);
                      waveSurferRef4.current.setVolume(volume4);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="abajoMix">
              <div className="textoMix">
                <span className="descMix">
                  Composición, Mezcla y Master del sencillo Apuestas, próximo a
                  salir del proyecto Flores de Octubre, próximamente disponible
                  en todas las plataformas digitales.
                </span>
              </div>
            </div>
          </div>

          {/* Sabras Mas */}
          <div className="contenidoMix">
            <h3 className="tipoMix">Sabrás - TOMI TONMIK Mixing session</h3>
            <div className="wave">
              <WaveSurfer plugins={plugins} onMount={handleWSMount5}>
                <WaveForm id="waveform5"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="mixMasterIcons">
                  <a onClick={play5} className="musicPlaying">
                    {isPlaying5 == false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="mixMasterIcons2">
                  <span className="volumeIcon">
                    {volume5 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume5 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInput"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume5}
                    onChange={(e) => {
                      setVolume5(e.target.valueAsNumber);
                      waveSurferRef5.current.setVolume(volume5);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="abajoMix">
              <div className="textoMix">
                <span className="descMix">
                  Proyecto final de la materia “Mezcla digital” el cual constó
                  en trabajar un mix dinámico y proponer una nueva dirección
                  creativa para la mezcla de la canción.
                </span>
              </div>
            </div>
          </div>

          {/* Cohete */}
          <div className="contenidoMix">
            <h3 className="tipoMix">Cohete - Renee Mastering session</h3>
            <div className="wave">
              <WaveSurfer plugins={plugins} onMount={handleWSMount6}>
                <WaveForm id="waveform6"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="mixMasterIcons">
                  <a onClick={play6} className="musicPlaying">
                    {isPlaying6 == false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="mixMasterIcons2">
                  <span className="volumeIcon">
                    {volume6 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume6 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInput"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume6}
                    onChange={(e) => {
                      setVolume6(e.target.valueAsNumber);
                      waveSurferRef6.current.setVolume(volume6);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="abajoMix">
              <div className="textoMix">
                <span className="descMix">
                  Proyecto final de la materia “Mastering” impartida por el
                  ingeniero Allan Tucker, el proyecto constó de realizar un dsp
                  con canciones sin masterización y realizar todo un proceso
                  profesional de masterización con su respectiva documentación y
                  presentación ante concurso.
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Mix;
